
export const IMAGES = {

    farmLooker: require("./FarmLooker.jpg"),
    aiCare: require("./AIcare.jpg"),
    bbTech: require("./bbTech.png"),
    // bgVideoGif: require("./bgVideoGIF.mp4"),
    
    heroBg: require("./hero-bg.png"),
    IPS: require("./IPS.jpg"),
    
    realEstate: require("./RealEstate.png"),
    samaAuto: require("./samaAuto.jpg"),
    sparkSchool: require("./sparkschool.jpg"),

    // bgVideo: require("./video.mp4")
    
}