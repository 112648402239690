import logo from './logo.svg';
import './App.css';
import { IMAGES } from './img';
import { VIDEO } from './video';
import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
    const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
    const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
    const menu_item = document.querySelectorAll('.header .nav-bar .nav-list ul li a');
    const header = document.querySelector('.header.container');

    const handleHamburgerClick = () => {
      hamburger.classList.toggle('active');
      mobile_menu.classList.toggle('active');
    };

    const handleScroll = () => {
      var scroll_position = window.scrollY;
      if (scroll_position > 250) {
        header.style.backgroundColor = '#29323c';
      } else {
        header.style.backgroundColor = 'transparent';
      }
    };

    const handleMenuItemClick = () => {
      hamburger.classList.toggle('active');
      mobile_menu.classList.toggle('active');
    };

    hamburger.addEventListener('click', handleHamburgerClick);
    document.addEventListener('scroll', handleScroll);

    menu_item.forEach((item) => {
      item.addEventListener('click', handleMenuItemClick);
    });

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('scroll', handleScroll);
      hamburger.removeEventListener('click', handleHamburgerClick);
      menu_item.forEach((item) => {
        item.removeEventListener('click', handleMenuItemClick);
      });
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount



  return (
    <div>
      <section id="header">
        <div className="header container">
          <div className="nav-bar">
            <div className="brand">
              <a href="#hero">
                <h1><span
                  style={{ fontWeight: 'normal', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                  bean</span><span style={{ fontStyle: 'italic', fontFamily: 'BookAntiqua' }}>bend</span></h1>
              </a>
              <h3 style={{ color: 'white', fontFamily: 'BookAntiqua', fontWeight: 'normal' }}>bending technology</h3>
            </div>
            <div className="nav-list">
              <div className="hamburger">
                <div className="bar"></div>
              </div>
              <ul>
                <li><a href="#hero" data-after="Home">Home</a></li>
                <li><a href="#services" data-after="Service">Services</a></li>
                <li><a href="#projects" data-after="Projects">Projects</a></li>
                <li><a href="#about" data-after="About">About</a></li>
                <li><a href="#contact" data-after="Contact">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section id="hero">
        <div>
          <video autoPlay muted loop className="video">
            <source src={VIDEO.bgVideoGif} type="video/mp4" />
            Your browser does not support the video.
          </video>

          <div className="hero-div">
            <h1>Hi, <span></span></h1>
            <h1>We Are <span></span></h1>
            <h1 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }}>
              bean<i style={{ fontFamily: 'BookAntiqua', fontWeight: 'bold' }}>bend</i><span></span></h1>
            <h3 style={{ fontFamily: 'BookAntiqua' }}>bending technology<span></span></h3>
            <a href="#projects" type="button" className="cta">Our Projects</a>
          </div>
        </div>
      </section>


      <section id="services">
        <div className="services container">
          <div className="service-top">
            <h1 className="section-title">Serv<span>i</span>ces</h1>
          </div>
          <div className="service-bottom">
            <div className="service-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>
              <h2>App Development</h2>
              <p>App Development refers to the creation of computer applications for use on mobile devices such as tablets,
                smartphones and smart watches. ... Mobile applications are designed and built for different operating
                systems and developers will want to learn how to build for both the Android operating system and Apple's iOS
              </p>
            </div>
            <div className="service-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>
              <h2>Web Mapping</h2>
              <p>A Web mapping or an online mapping is the process of using the maps delivered by geographic information
                systems (GIS) on the Internet. A web map or an online map is both served and consumed, thus web mapping is
                more than just web cartography, it is a service by which consumers may choose what the map will show.</p>
            </div>
            <div className="service-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>
              <h2>Digital Farming</h2>
              <p>The new agricultural players are incorporating efficient technologies into their farming systems. The use
                of IoT and data analytics helps farmers control environmental conditions to ensure the proper growth of
                crops. This is by determining the sunlight, humidity, light, and other essential elements.</p>
            </div>

            <div className="service-item">
              <br /><br />
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>

              <h2>Data Analytics & Visualization</h2>
              <p>This service involves collecting, processing, and analyzing large datasets to extract valuable insights and
                trends. Data analysts and data scientists use statistical techniques, machine learning algorithms, and
                visualization tools to present the findings in a visually appealing and understandable manner.</p>
            </div>
            <div className="service-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>
              <h2>CRM Development</h2>
              <p>We excel in building Customer Relationship Management (CRM) sys- tems tailored to your unique business
                needs. Our CRM solutions are designed to enhance customer interactions, streamline processes, and optimize
                overall business performance.</p>
            </div>
            <div className="service-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/services.png" /></div>
              <h2>Predictive Modeling & Machine Learning</h2>
              <p>Harness the power of predictive modeling with our machine learning solutions. From recommendation systems
                to fraud detection, we lever- age historical data to build models that make accurate predictions about
                future outcomes.</p>
            </div>

          </div>
        </div>
      </section>


      <section id="projects">

        <div className="projects-header">
          <h1 className="section-title">Recent <span>Projects</span></h1>
        </div>
        <div className="all-projects">
          <div className="project-item">
            <div className="project-info">
              <h1>FarmCare</h1>
              <h2>Farming is Love</h2>
              <p>FarmCare is an all-in-one software application platform to help you manage your livestock, farm and keep
                your data in one place in a digital format.
                - It can be used with your phone or your desktop computer for different types of livestock.</p>
            </div>
            <div className="project-img">
              {/* <img src="./img/FarmLooker.jpg" alt="img"/> */}
              <img src={IMAGES.farmLooker} alt='img' />
            </div>
          </div>

          <div className="project-item">
            <div className="project-info">
              <h1>CRM for a School System</h1>
              <h2>ERP System</h2>
              <p>We've developed a comprehensive CRM for Spark Schools South Africa, including sub-units such as Financial
                Management, Human Resource Man- agement, Application Management, Resource Management, and Lead Management.
              </p>
            </div>
            <div className="project-img">
              <img src={IMAGES.sparkSchool} alt="img" />
            </div>
          </div>

          <div className="project-item">
            <div className="project-info">
              <h1>Indoor Positioning System (IPS)</h1>
              <h2>Gain Insights</h2>
              <p>Indoor positioning systems (IPS) are similar to GPS for indoor locations. These systems can detect
                real-time positions to determine the coordinates of people or assets inside a building using a variety of
                approaches. To give wayfinding and additional context to users, these coordinates are generally displayed
                visually by a blue dot on a digital indoor map.

              </p>
            </div>
            <div className="project-img">
              <img src={IMAGES.IPS} alt="img" />
            </div>
          </div>
          <div className="project-item">
            <div className="project-info">
              <h1>CitiWorth</h1>
              <h2>Real Estate is the future.</h2>
              <p>A property portal like CitiWorth is the most authentic way to avoid property scams in Pakistan and get in
                touch with agents who can be tracked and trusted. With the help of Zameen, you can find properties and
                contact their agents all over the country with just a few clicks</p>
            </div>
            <div className="project-img">
              <img src={IMAGES.realEstate} alt="img" />
            </div>
          </div>
          <div className="project-item">
            <div className="project-info">
              <h1>Taxi App</h1>
              <h2>Coding is Love</h2>
              <p>The taxi booking applications are a solution to several problems like reducing the waiting time on the
                road, looking out for cabs under the sun, and several other things. There are several benefits one can draw
                from these apps. Apart from the passengers, there are several advantages for the drivers as well.</p>
            </div>
            <div className="project-img">
              <img src={IMAGES.samaAuto} alt="img" />
            </div>
          </div>

          <div className="project-item">
            <div className="project-info">
              <h1>AI Based Customer Care</h1>
              <h2>Intelligent & Responsive</h2>
              <p>AI-based customer care utilizes artificial intelligence to handle customer inquiries, provide assistance,
                and streamline support processes, improving efficiency and response times. It often involves chatbots,
                virtual assistants, and automated systems to address common issues and guide users through solutions.</p>
              <a style={{ fontSize: 'large', color: '#8DBCA6' }} href="http://3.89.125.65/"><b>Demo</b></a>
            </div>
            <div className="project-img">
              <img src={IMAGES.aiCare} alt="img" />
            </div>
          </div>


        </div>


      </section>




      <section id="contact">
        <div className="contact container">
          <div>
            <h1 className="section-title" style={{ marginBottom: '50px' }}>Contact <span>info</span></h1>
          </div>
          <div className="contact-items">
            <div className="contact-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/phone.png" /></div>
              <div className="contact-info">
                <h1>Phone</h1>
                <h2><b>South Africa:</b><br /> +27 718 361 820</h2>
                <h2><b>Pakistan:</b><br /> +92 314 1800010</h2>

              </div>
            </div>
            <div className="contact-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/new-post.png" /></div>
              <div className="contact-info">
                <h1>Email</h1>
                <h2><b>Email:</b> contact@beanbend.com</h2>

              </div>
            </div>
            <div className="contact-item">
              <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/map-marker.png" /></div>
              <div className="contact-info">
                <h1>Address</h1>
                <h2><b>South Africa:</b><br /> 5334 Freedom Park Devland Gauteng 1811</h2>
                <h2><b>Pakistan:</b> <br />New City Phase 2, Islamabad</h2>
              </div>
            </div>

          </div>
        </div>

      </section>



      <section id="footer">
        <div className="footer container">
          <div className="brand">
            <h1
              style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal', color: '#8DBCA6' }}>
              bean<i style={{ fontFamily: 'BookAntiqua', fontWeight: 'bold' }}>bend</i></h1>
            <h3 style={{ fontFamily: 'BookAnttiqua', color: '#8DBCA6' }}>bending technology</h3>
          </div>


          <p>Copyright © 2020 beanbend bending technology. All rights reserved</p>
        </div>
      </section>

      <script src="./app.js"></script>
    </div>
  );
}

export default App;
